.bo-funding-tab .RT-Section {
  min-height: 350px;
}

.funding-tab .RT-Section {
  min-height: 320px;
}

.pa-portal-detail-tab .RT-Section {
  min-height: 310px;
}

.funding-tab {
  padding-right: 15px;
}

.notification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  padding: 15px 0px;
}

.notification-label {
  padding-bottom: 15px;
  font-weight: 500;
}

.notification-desc {
  padding-right: 50px;
}

.notification-balance {
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 300px;
  min-width: 290px;
  height: 80px;
  color: #223e7f;
  background-color: #fff;
}

.notification-balance {
  display: flex;
  align-items: center;
  position: relative;
}

.notification-icon {
  margin-right: 15px;
  margin-left: 15px;
}

.notification-edit {
  position: absolute;
  top: 15px;
  right: 15px;
}

.shortfall-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.shortfall-form {
  align-self: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 300px;
}

.shortfall-input {
    padding: 15px 20px 0 20px;
}

.shortfall-btn {
    background-color: #559e83 !important;
    color:#fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
}

.shortfall-btn:hover {
    background-color: #3d7260 !important;
    color:#fff;
}

.shortfall-btn:focus {
    background-color: #3d7260;
    box-shadow: 0 0 0 0.25rem rgba(61, 114, 96, .25); 
}

.shortfall-btn svg {
    margin-right: 8px; 
  }