.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.message-wrapper {
  width: 400px;
  max-width: 100%;
  background-color: #223e7f;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 3px;
  margin: 0 auto;
}

.message-logo{
  text-align: center;
  width: 70%;
  margin-bottom: 20px;
}

.wd-300 {
  width: 300px;
}

.wd-xs-500 {
  width: 500px;
}

.ht-100v {
  height: 100vh;
}

.pd-xs-40 {
  padding: 40px;
}

.pd-25 {
  padding: 25px;
}

.rounded {
  border-radius: 3px !important;
}

.shadow-base {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

.bg-white {
  background-color: #fff !important;
}

.bg-br-primary {
  background-color: #223e7f;
}

.custom-p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.custom-img {
  display: contents;
}