.RT-TextBox.add-employee-waiting-period{
    width: 180px;
}

.add-employee-waiting-period .dropdown {
    padding: 0px;
    position: absolute;
    margin-right: 30px;
    width: 160px;
}

.add-employee-waiting-period .dropdown-menu {
    position: absolute;
    width: initial;
    min-width: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.add-employee-waiting-period .btn.btn-secondary.dropdown-toggle
{
    height: calc(1.5em + 0.75rem + 2px) !important;
    text-align: left;
}

.edit-eligibility .dropdown-menu {
    position: relative !important;
}

.add-employee-waiting-period .dropdown .btn-label {
    width: 80px;
}

.add-employee-waiting-period .btn.btn-secondary.dropdown-toggle .dropdown-icon
{
    float: right;
    margin-top: 5px;
    font-size: 12px;
}

.RT-TextBox.add-employee-ben-cat{
    width: 350px;
}

.add-employee-ben-cat .dropdown {
    padding: 0px;
    position: absolute;
    margin-right: 30px;
    width: 350px;
}

.add-employee-ben-cat .dropdown-menu {
    position: absolute;
    width: initial;
    min-width: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.add-employee-ben-cat .btn.btn-secondary.dropdown-toggle
{
    height: calc(1.5em + 0.75rem + 2px) !important;
    text-align: left;
}

.add-employee-ben-cat .dropdown .btn-label {
   width: 280px;
}

.add-employee-ben-cat .btn.btn-secondary.dropdown-toggle .dropdown-icon
{
    float: right;
    margin-top: 5px;
    font-size: 12px;
}

.termination-dropdown-container .dropdown {
    padding: 0px;
    margin-right: 0px;
    width: 365px;
}
.termination-dropdown-container .dropdown-menu {
    position: fixed;
    z-index:  1;
    width: 365px;
}

.termination-dropdown-container .dropdown-toggle {
    height: 38px !important;
}

.employee-edit-bencat .dropdown {
    padding: 0px;
    margin-right: 0px;
    width: 250px !important;
}

.employee-edit-bencat .dropdown-menu {
    position: fixed !important;
    width: initial !important;
    min-width: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.terminate-dropdown .btn.btn-secondary.dropdown-toggle {
    height: 37px;
    color: rgb(73, 80, 87);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgb(196, 205, 213);
    border-radius: 3px;
    width: 100%;
    padding: 5px 5px;
  }
  
  .terminate-dropdown .selected-opt {
    width: 50px;
    margin-right: 150px;
    display: inherit;
    font-size: 14px;
  }

  .data-grid.business-owner .RT-DataTable--pull-padding {
    height: 70%;
    flex-grow: unset;
  }

  .RT-Stackable-Container--business-owner .RT-Stackable-Container__body {
    overflow-y: hidden;
  }

  .RT-TextBox.add-employee-teladoc-waiting-period{
    width: 200px;
}

.add-employee-teladoc-waiting-period .dropdown {
    padding: 0px;
    position: absolute;
    margin-right: 30px;
    width: 200px;
}

.add-employee-teladoc-waiting-period .dropdown-menu {
    position: absolute;
    width: initial;
    min-width: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.add-employee-teladoc-waiting-period .btn.btn-secondary.dropdown-toggle
{
    height: calc(1.5em + 0.75rem + 2px) !important;
    text-align: left;
}

.add-employee-teladoc-waiting-period .dropdown .btn-label {
    width: 80px;
}

.add-employee-teladoc-waiting-period .btn.btn-secondary.dropdown-toggle .dropdown-icon
{
    float: right;
    margin-top: 5px;
    font-size: 12px;
}

.remove-bonus-credit-modal .modal-dialog {
    max-width: 400px;
}