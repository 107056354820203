.dropdown {
  padding: 10px; 
  position: relative;
  display: inline-block;
  margin-right: 50px;
  width: 250px;
}

.btn-label {
  width: 180px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.dropdown-icon {
  margin-left: 5px;
  float: right;
  position: relative;
  top: 5px;
  font-size: 12px;
}

.dropdown-menu {
  display: block;
  width: inherit;
  padding: 0%;
  max-height: 300px;
}

.dropdown-menu:focus {
  outline: none;
}

.btn.btn-secondary.dropdown-toggle {
  height: 35px;
  background-color: transparent;
  color: black;
  border: 1px solid #c4cdd5;
  width: inherit;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  cursor: pointer;
}

.edit-planadmin-setting-modal {
  top:200px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.edit-planadmin-setting-modal label {
  margin-bottom: 0px;
  margin-left: 5px;
}

.pa-role-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pa-role-dropdown-container .dropdown {
  margin-right: 0px;
  padding: 0px;
  width: 200px;
}

.pa-role-dropdown-container .dropdown-menu {
  position: fixed;
}
.pa-role-dropdown-container .btn-label {
  width: 150px;
}