.selection-options {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    position: relative;
    padding: 10px 0px 10px 0px;
}

.merge-tags {
    color: #848f99;
}
.merge-tags-table {
    min-height: 300px;
    min-width: 100%;
    color: #16325c;
    border: 1px solid #d8dde6;
    border-radius: 0.25rem;
    width: 100%;
    background-color: #f7f8f9;
    resize: vertical;
    padding: 0.5rem 0.75rem;
}

.selection-email-template-content {
    color: #848f99;
}

.email-template-textarea {
    min-height: 400px;
    min-width: 100%;
    color: #16325c;
    border: 1px solid #d8dde6;
    border-radius: 0.25rem;
    width: 100%;
    transition: border .1s linear,background-color .1s linear;
    resize: vertical;
    padding: 0.5rem 0.75rem;
}

.default-checkbox {
    margin-right: 10px;
}

.custom-checkbox {
    margin-right: 10px;
}

.merge-tags-table.table>:not(:first-child) {
    border-top: solid 1px #c2c4c6;;
}

.merge-tags-table .table>thead {
    font-size: 12px;
}

.flex-templates-buttons {
    padding: 10px;
}