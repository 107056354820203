.home-camera-hsa {
  margin-top: 0 !important;
  background-color: #223e7f;
  border-color: #223e7f;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 10px 30px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  font-size: 13px;
  width: 170px;
}

.home-camera-inv-hsa {
  margin-top: 0 !important;
  background-color: #fff;
  border-color: #223e7f;
  color: #223e7f;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 10px 30px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  font-size: 13px;
  width: 170px;
}

.home-camera-lsa {
  margin-top: 0 !important;
  background-color: #059454;
  border-color: #059454;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 10px 30px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  font-size: 13px;
  width: 170px;
}

.home-camera-inv-lsa {
  margin-top: 0 !important;
  background-color: #fff;
  border-color: #059454;
  color: #059454;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 10px 30px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  font-size: 13px;
  width: 170px;
}

.home-camera-hsa:hover,
.home-camera-hsa:active {
  color: #fff;
  background-color: #1b3165;
  border-color: #1b3165;
}

.home-camera-inv-hsa:hover,
.home-camera-inv-hsa:active {
  color: #1b3165;
  background-color: #fff;
  border-color: #1b3165;
}

.home-camera-lsa:hover,
.home-camera-lsa:active {
  color: #fff;
  background-color: #047b46;
  border-color: #047b46;
}

.home-camera-inv-lsa:hover,
.home-camera-inv-lsa:active {
  color: #047b46;
  background-color: #fff;
  border-color: #047b46;
}

.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.pd-20 {
  padding: 20px;
}

.mg-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tx-available {
  margin-top: -45px;
  font-size: 9px;
  text-transform: uppercase;
}

.card-font-color {
  color: #5a6070;
}

.pd-x-25-force {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.pd-10 {
  padding: 10px;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-t-15 {
  padding-top: 15px;
}

.pd-t-30 {
  padding-top: 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}

.row-upload {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: initial;
}


.print-button {
  margin-top: 0 !important;
  background-color: #223e7f;
  border-color: #223e7f;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 3px 13px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  width: 182px;
}

.print-button:hover,
.print-button:active {
  color: #fff;
  background-color: #1b3165;
  border-color: #1b3165;
}

.mg-r-15 {
  margin-right: 15px;
}




.tx-available-val {
  font-size: 0.875rem;
  margin-top: 8px;
  font-weight: bold;
}

.btn-submit {
  margin-top: 0 !important;
  background-color: #4987ef;
  border-color: #4987ef;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 0;
  border-radius: 45px;
  padding: 3px 25px;
  min-height: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  line-height: 30px;
}

.btn-submit:hover,
.btn-submit:active {
  color: #fff;
  background-color: #1b3165;
  border-color: #1b3165;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.br-pagebody {
  margin-top: 10px;
  padding: 0 15px;
}
@media (min-width: 576px) {
  .br-pagebody {
    padding: 0 30px;
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.br-section-wrapper {
  margin-bottom: 40px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.no-padding {
  padding: 0;
}

.tx-medium {
  font-weight: 500;
}

.tx-uppercase {
  text-transform: uppercase;
}


.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.mg-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-t-20 {
  margin-top: 20px;
}

.float-left {
  float: left !important;
}

.text-center {
  text-align: center !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.btn-danger {
  color: #fff;
  background-color: #ef4e3b;
  border-color: #ef4e3b;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ec2e18;
  border-color: #e42913;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tx-14 {
  font-size: 14px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.tx-inverse {
  color: #343a40;
}

.tx-bold {
  font-weight: 700;
}

.column {
  flex: 50%;
  padding: 10px;
  align-items: center;
  display: grid;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
