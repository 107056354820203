.ktm-reports {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.ktm-reports-message {
  font-size: 15px;
  font-weight: 500;
  padding-left: 10px;
  padding-bottom: 10px;
  display: block;
}

.list-group-item {
  margin-bottom: 10px; 
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}

.ktm-reports-content {
  width: 88%;
  position: relative;
}
.ktm-financials-content {
  min-width: 900px;
  position: relative;
}
.ktm-financials-label {
  font-size: 16px;
  font-weight: 500;
}

.report-table-container {
    position: relative;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.report-table th, .report-table td {
  text-align: end;
}

.table-text-content {
  text-align: left !important;
}
.financial-spinner > div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 150px auto;
}

/* .benefit-year-status {
  position: absolute;
  top: 20px;
  left: 300px;
} */

.export-to-csv {
  background-color: #0d6efd;
  border-radius: 0.25rem;
  height: 35px;
  padding: 1px 5px;
  /* position: absolute;
  right: 50px; */
}

.export-to-csv:hover {
  background-color: #0d6efd;
}

.export-to-pdf {
  background-color: #0d6efd;
  border-radius: 0.25rem;
  height: 35px;
  padding: 1px 5px;
  /* position: absolute;
  right: 50px; */
}

.export-to-pdf:hover {
  background-color: #0d6efd;
}

.date-range-picker-container {
  position: absolute;
  right: 400px;
  top: 10px;
}
.date-range-picker .react-date-picker__wrapper {
  height: 35px;
  width: 130px;
}
.calendar-picker-container {
  display: flex;
  flex-direction: column;
  z-index: 1001;
  width: 620px;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  position: absolute;
  left: 260px;
}
.calendar-picker-container .btn.apply {
    width: 70px;
    font-size: 14px;
    margin-top: 10px;
    padding: 2px 5px;
}
.calendar-pickers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.react-calendar.from-calendar {
  width: 300px;
  height: 300px;
  font-size: 13px;
  border: none;
}

.react-calendar.to-calendar {
  width: 300px;
  height: 300px;
  font-size: 13px;
  border: none;
}

.dropdown-menu.report {
  z-index: 1001;
  display: block;
  position: absolute;
}

.date-picker-lable {
  font-size: 13px;
  font-weight: 500;
  color: #868686;
  margin-left: 140px;
}

.calendar-picker-btns {
  position: relative;
  padding: 10px;
  left: 430px;
  width: 1900px;
  margin-top: 20px;
}

.calendar-picker-btns .RT-Button {
  margin-right: 20px;
}

.calendar-picker-btns .save-btn {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.table-footer-item {
  font-size: 14px;
  font-weight: bold;
}

.table-footer-item.total-amount {
  text-align: left;
}

.divisions-table .dropdown {
  width: 180px;
}

.divisions-dropdown-container .dropdown-menu {
  width: initial !important;
  min-width: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}

.divisions-table .btn-label {
  width: 100px;
}

.divisions-label {
  position: absolute;
  left: 40px;
  top: 17px;
  font-size: 15px;
}

.divisions-deactivated-employees {
  margin-top: 17px;
  font-size: 14px;
}

.utilization-deactivated-employees {
  position: absolute;
  right: 300px;
  font-size: 14px;
  margin-top: 10px;
}

.costCentres-table .dropdown {
  width: 180px;
}

.cost-centres-dropdown-container .dropdown-menu {
  width: initial !important;
  min-width: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}

.costCentres-table .btn-label {
  width: 100px;
}

.costcentres-deactivated-employees {
  margin-top: 17px;
  font-size: 14px;
}

.date-range-picker {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 20px;
}

.empty-state-template{
  text-align: center;
  margin-top: 150px;
  top: 150px;
  left: 0;
  right: 0;
}

.asc-icon {
  position: relative;
  top: 4px;
  left: 10px;
}

.desc-icon {
  position: relative;
  bottom: 4px;
  left: 10px;
}

.export-button-container {
  display: flex;
  position: absolute;
  right: 40px;
  grid-gap: 20px;
  gap: 20px;
}