
/* CIRCULAR PROGRESS */

.progress-circle, .progress-circle-green {
    position: relative;
    display: inline-block;
    margin: 1rem 1rem 1rem 0.25rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: transparent; }
  
  .progress-circle:after,.progress-circle-green:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: colorload 2s;
    animation: colorload 2s;
    -webkit-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .progress-circle span,
  .progress-circle-green span {
    font-size: 1rem;
    color: #223e7f;
    position: absolute;
    left: 40%;
    top: 40%;
    display: block;
    width: 80px;
    height: 80px;
    line-height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    z-index: 1; 
  
    -webkit-box-shadow: inset 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .progress-circle span:after, .progress-circle-green span:after {
    content: " ";
    font-weight: 600;
    color: #223e7f; 
  }
  
  .progress-circle.progress-0:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(90deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-1:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(93.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-2:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(97.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-3:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(100.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-4:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(104.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-5:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(108deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-6:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(111.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-7:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(115.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-8:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(118.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-9:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(122.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-10:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(126deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-11:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-12:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-13:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-14:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-15:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-16:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-17:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-18:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-19:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(144deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-20:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(151.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-21:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(158.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-22:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(161.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-23:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(165.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-24:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(169.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-25:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(180deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-26:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(183.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-27:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(187.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-28:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(190.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-29:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(194.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-30:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(198deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-31:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(201.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-32:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(205.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-33:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(208.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-34:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(212.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-35:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(216deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-40:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(234deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-36:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(219.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-37:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(223.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-38:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(226.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-39:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(230.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-41:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(237.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-42:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(241.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-43:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(244.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-44:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(248.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-45:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(252deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-46:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(255.6deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-47:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(259.2deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-48:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(262.8deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-49:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(266.4deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-50:after {
    background-image: linear-gradient(-90deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-51:after {
    background-image: linear-gradient(-86.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-52:after {
    background-image: linear-gradient(-82.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-53:after {
    background-image: linear-gradient(-79.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-54:after {
    background-image: linear-gradient(-75.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-55:after {
    background-image: linear-gradient(-72deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-56:after {
    background-image: linear-gradient(-68.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-57:after {
    background-image: linear-gradient(-64.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-58:after {
    background-image: linear-gradient(-61.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-59:after {
    background-image: linear-gradient(-57.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-60:after {
    background-image: linear-gradient(-54deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-61:after {
    background-image: linear-gradient(-50.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-62:after {
    background-image: linear-gradient(-46.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-63:after {
    background-image: linear-gradient(-43.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-64:after {
    background-image: linear-gradient(-39.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-65:after {
    background-image: linear-gradient(-36deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-66:after {
    background-image: linear-gradient(-32.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-67:after {
    background-image: linear-gradient(-28.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-68:after {
    background-image: linear-gradient(-25.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-69:after {
    background-image: linear-gradient(-21.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-70:after {
    background-image: linear-gradient(-18deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-71:after {
    background-image: linear-gradient(-14.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-72:after {
    background-image: linear-gradient(-10.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-73:after {
    background-image: linear-gradient(-7.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-74:after {
    background-image: linear-gradient(-3.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-75:after {
    background-image: linear-gradient(0deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-76:after {
    background-image: linear-gradient(3.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-77:after {
    background-image: linear-gradient(7.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-78:after {
    background-image: linear-gradient(10.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-79:after {
    background-image: linear-gradient(14.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-80:after {
    background-image: linear-gradient(18deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-81:after {
    background-image: linear-gradient(21.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-82:after {
    background-image: linear-gradient(25.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-83:after {
    background-image: linear-gradient(28.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-84:after {
    background-image: linear-gradient(32.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-85:after {
    background-image: linear-gradient(36deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-86:after {
    background-image: linear-gradient(39.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-87:after {
    background-image: linear-gradient(43.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-88:after {
    background-image: linear-gradient(46.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-89:after {
    background-image: linear-gradient(50.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-90:after {
    background-image: linear-gradient(54deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-91:after {
    background-image: linear-gradient(57.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-92:after {
    background-image: linear-gradient(61.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-93:after {
    background-image: linear-gradient(64.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-94:after {
    background-image: linear-gradient(68.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-95:after {
    background-image: linear-gradient(72deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-96:after {
    background-image: linear-gradient(75.6deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-97:after {
    background-image: linear-gradient(79.2deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-98:after {
    background-image: linear-gradient(82.8deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-99:after {
    background-image: linear-gradient(86.4deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle.progress-100:after {
    background-image: linear-gradient(90deg, #223e7f 50%, transparent 50%, transparent), linear-gradient(270deg, #223e7f 50%, #ffffff 50%, #ffffff); }
  
  /* Progress Green */
  .progress-circle-green.progress-0:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(90deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-1:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(93.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-2:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(97.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-3:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(100.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-4:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(104.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-5:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(108deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-6:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(111.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-7:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(115.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-8:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(118.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-9:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(122.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-10:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(126deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-11:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-12:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-13:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-14:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-15:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-16:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-17:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-18:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-19:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(144deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-20:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(151.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-21:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(158.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-22:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(161.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-23:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(165.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-24:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(169.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-25:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(180deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-26:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(183.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-27:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(187.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-28:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(190.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-29:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(194.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-30:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(198deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-31:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(201.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-32:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(205.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-33:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(208.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-34:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(212.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-35:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(216deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-40:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(234deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-36:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(219.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-37:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(223.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-38:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(226.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-39:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(230.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-41:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(237.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-42:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(241.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-43:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(244.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-44:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(248.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-45:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(252deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-46:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(255.6deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-47:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(259.2deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-48:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(262.8deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-49:after {
    background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(266.4deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-50:after {
    background-image: linear-gradient(-90deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-51:after {
    background-image: linear-gradient(-86.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-52:after {
    background-image: linear-gradient(-82.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-53:after {
    background-image: linear-gradient(-79.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-54:after {
    background-image: linear-gradient(-75.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-55:after {
    background-image: linear-gradient(-72deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-56:after {
    background-image: linear-gradient(-68.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-57:after {
    background-image: linear-gradient(-64.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-58:after {
    background-image: linear-gradient(-61.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-59:after {
    background-image: linear-gradient(-57.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-60:after {
    background-image: linear-gradient(-54deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-61:after {
    background-image: linear-gradient(-50.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-62:after {
    background-image: linear-gradient(-46.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-63:after {
    background-image: linear-gradient(-43.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-64:after {
    background-image: linear-gradient(-39.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-65:after {
    background-image: linear-gradient(-36deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-66:after {
    background-image: linear-gradient(-32.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-67:after {
    background-image: linear-gradient(-28.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-68:after {
    background-image: linear-gradient(-25.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-69:after {
    background-image: linear-gradient(-21.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-70:after {
    background-image: linear-gradient(-18deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-71:after {
    background-image: linear-gradient(-14.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-72:after {
    background-image: linear-gradient(-10.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-73:after {
    background-image: linear-gradient(-7.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-74:after {
    background-image: linear-gradient(-3.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-75:after {
    background-image: linear-gradient(0deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-76:after {
    background-image: linear-gradient(3.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-77:after {
    background-image: linear-gradient(7.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-78:after {
    background-image: linear-gradient(10.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-79:after {
    background-image: linear-gradient(14.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-80:after {
    background-image: linear-gradient(18deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-81:after {
    background-image: linear-gradient(21.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-82:after {
    background-image: linear-gradient(25.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-83:after {
    background-image: linear-gradient(28.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-84:after {
    background-image: linear-gradient(32.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-85:after {
    background-image: linear-gradient(36deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-86:after {
    background-image: linear-gradient(39.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-87:after {
    background-image: linear-gradient(43.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-88:after {
    background-image: linear-gradient(46.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-89:after {
    background-image: linear-gradient(50.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-90:after {
    background-image: linear-gradient(54deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-91:after {
    background-image: linear-gradient(57.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-92:after {
    background-image: linear-gradient(61.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-93:after {
    background-image: linear-gradient(64.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-94:after {
    background-image: linear-gradient(68.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-95:after {
    background-image: linear-gradient(72deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-96:after {
    background-image: linear-gradient(75.6deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-97:after {
    background-image: linear-gradient(79.2deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-98:after {
    background-image: linear-gradient(82.8deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-99:after {
    background-image: linear-gradient(86.4deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  .progress-circle-green.progress-100:after {
    background-image: linear-gradient(90deg, #059454 50%, transparent 50%, transparent), linear-gradient(270deg, #059454 50%, #ffffff 50%, #ffffff); }
  
  @-webkit-keyframes colorload {
    0% {
      opacity: 0;
      transform: rotate(0deg); }
    100% {
      opacity: 1;
      transform: rotate(360deg); } 
  }
  
  @media (max-width: 320px) {
    .coverage-label {
      font-size: 12px !important;
    }
    .coverage-sub-label {
      font-size: 10px !important;
    }
    .coverage-sub-button {
      margin-left: 0 !important;
      margin-top: 8px !important;
    }
  }
  
  .progress-hide {
    display: none;
  }