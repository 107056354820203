.react-modal.edit-campaign-modal .modal-dialog {
    width: 500px;
}

.react-modal.edit-campaign-modal .modal-title {
    font-size: 17px;
}

.react-modal.edit-campaign-modal .date-picker-container {
    padding-top: 10px;
    padding-left: 20px;
}

.react-modal.edit-campaign-modal .react-date-picker {
    width: 150px;
}

.react-modal.edit-campaign-modal .react-date-picker__inputGroup {
    padding: 0px 10px;
}

.react-modal.edit-campaign-modal .RT-Section-Header {
    margin-bottom: 10px;
}

.react-modal.edit-campaign-modal .RT-Section-Header-Title {
    font-size: 12px;
    font-weight: 500;
}

.campaign-info-container {
    margin-bottom: 10px;
}

.employee-campaign-button-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.employee-campaign-button-container .btn-secondary {
    margin-right: 30px;
    height: 40px;
}

.employee-campaign-button-container .btn-primary {
    height: 40px
}

.employee-campaign-button-container .pagination-container {
    position: absolute;
    right: 10px;
    margin: 0px;
}

.edit-employee-campaign-modal-container {
    top: 20%;
}

.edit-employee-campaign-modal-container .card {
    padding: 10px;
}

.campaign-edit-employee-modal-header .modal-title {
    font-size: 17px;
    font-weight: 500;
}

.campaign-edit-employee-modal-body .btn.btn-secondary.dropdown-toggle {
    background-color: white;
}

.campaign-edit-employee-modal-body .dropdown {
    padding: 0px;
    margin-left: 10px;
}

.campaign-edit-employee-modal-body .dropdown-menu {
    position: fixed !important;
    width: initial !important;
    min-width: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.campaign-edit-employee-modal-body .RT-Label {
    margin-left: 10px;
}

.edit-employee-campaign-modal-container .date-picker-container {
    display: flex;
    flex-direction: column;
}

.edit-employee-campaign-modal-container .react-date-picker {
    margin-left: 10px;
    width: 250px;
    height: 35px;
}

.react-date-picker__wrapper {
    border: 1px solid lightgray !important;
    border-radius: 0.25rem;
}