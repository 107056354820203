.appearance-settings {
    width: 88%;
    position: relative;
}

.appearance-settings .RT-Static-Field__label {
    margin-bottom: 0px;
}

.react-modal.edit-general-setting-modal .modal-dialog {
    width:500px;
}

.react-modal.edit-general-setting-modal .btn.btn-secondary.dropdown-toggle {
    background-color: white;
}

.react-modal.edit-general-setting-modal .dropdown-menu {
    position: fixed !important;
    width: initial !important;
    min-width: 250px;
}

.react-modal.edit-general-setting-modal .RT-Label {
    margin-left: 10px;
}

.chrome-picker {
    box-shadow: none !important;
}

.react-modal.edit-company-status-modal .modal-dialog {
    width:550px;
}

.edit-company-status-modal .modal-title {
    font-size: 17px;
}

.edit-company-status-modal-body .dropdown {
    padding: 0px;
}

.edit-company-status-modal-body .dropdown-menu {
    position: fixed !important;
    width: initial !important;
    min-width: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

.date-picker-container {
    display: flex;
    flex-direction: column;
}

.edit-company-status-modal-body .react-date-picker {
    width: 200px;
    height: 35px;
}

.edit-company-status-modal-body .btn-label {
    width: 150px;
}

.edit-company-status-modal-body .dropdown {
    width: 200px;
}

.Termination-Note {
    width: 480px;
}

.terminate-employee-modal .react-date-picker {
    width: 300px;
    height: 38px;
}

.terminate-employee-modal .Termination-Note {
    width: 740px;
}

.employee-bulk-edits-container .truncate {
    max-width: 200px; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}