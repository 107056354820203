.br-menu-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 11px;
    height: 40px;
    letter-spacing: 0.2px;
    color: #adb5bd;
    font-size: 14px;
    position: relative;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
  }
  .br-menu-link.with-sub::after {
    content: '\f107';
    font-family: 'FontAwesome';
    position: absolute;
    font-size: 14px;
    top: 9px;
    right: 13px;
    color: #6c757d;
    transition: all 0.2s ease-in-out;
  }
  .br-menu-link:hover, .br-menu-link:focus {
    color: #fff;
    background-color: #1f3873;
  }
  .br-menu-link:focus, .br-menu-link:active {
    outline: none;
  }
  .br-menu-link.active {
    background-image: linear-gradient(to right, #1CAF9A 0%, #17A2B8 100%);
    background-repeat: repeat-x;
    color: #fff;
  }
  .br-menu-link.active::after {
    color: #fff;
  }
  .br-menu-link.active + .br-menu-sub {
    display: block;
  }
  .br-menu-link.show-sub {
    background-color: #1d346b;
    color: #fff;
  }
  
  .menu-item-icon {
    line-height: 0;
    width: 18px;
    transition: all 0.2s ease-in-out;
  }
  
  .menu-item-label {
    display: block;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: auto;
  }
  
  .menu-item-label,
  .menu-item-arrow {
    transition: all 0.2s ease-in-out;
  }
  
  .br-menu-sub {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 21px;
    background-color: #203a77;
    display: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .br-menu-sub .sub-item {
    display: block;
    position: relative;
  }
  .br-menu-sub .sub-link {
    display: block;
    font-size: 13px;
    color: #adb5bd;
    padding: 7px 0 7px 17px;
    white-space: nowrap;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  .br-menu-sub .sub-link::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -3px;
    margin-top: -.5px;
    width: 5px;
    height: 1px;
    background-color: #fff;
    opacity: .5;
    transition: all 0.2s ease-in-out;
  }
  .br-menu-sub .sub-link:hover, .br-menu-sub .sub-link:focus {
    color: #17A2B8;
  }
  .br-menu-sub .sub-link:hover::before, .br-menu-sub .sub-link:focus::before {
    width: 20px;
    left: -11px;
    opacity: 1;
    background-color: #17A2B8;
  }
  .br-menu-sub .sub-link.active {
    color: #17A2B8;
  }
  .br-menu-sub .sub-link.active::before {
    width: 20px;
    left: -11px;
    opacity: 1;
    background-color: #17A2B8;
  }