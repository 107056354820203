.benefit-category-table-container {
  text-align: left;
  font-weight: 500;
  color: #848f99;
  font-size: 12px;
}

.benefit-category-column {
  padding: 7px 6px;
  border-left: solid 1px transparent;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

.benefit-category-table {
  overflow-y: scroll; 
  display: block;
  flex-flow: column;
  width: 100%;
  height: 700px;
  border-collapse: collapse;
}

.expense-type-table {
  overflow-y: scroll; 
  display: block;
  flex-flow: column;
  width: 100%;
  height: 300px;
  border-collapse: collapse;
  padding-top: 20px;
}

.custom-table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 400px;
  border-collapse: collapse;
  width: 100%;
}

.custome-thead {
  padding-right: 13px;
  flex: 0 0 auto;
}

.custom-tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}
