html {
  background-color: #dce1e6;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bank-list {
  margin: 10px;
}
.bank-list li {
  list-style: disc;
  margin-left: 20px;
}

.funding-summary-card {
  margin: 20px auto;
  /* box-shadow: 0 0 10px #e3e6ec !important; */
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.funding-summary-card .RT-Table__td {
  padding: 15px 15px !important;
}

.funding-minimum {
  background-color: white;
  background-color: #ffd5d4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
tr.RT-Table__tr.funding-minimum td:nth-last-child(1) {
  border-bottom-right-radius: 10px;
}
tr.RT-Table__tr.funding-minimum td:nth-last-child(3) {
  border-bottom-left-radius: 10px;
}

.funding-summary-card td {
  padding: 10px !important;
}

.nhc-nav-bar {
  height: 65px;
  padding: 15px 25px;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  max-height: 100%;
  padding: 3px 0;
}

.home-card-container {
  max-width: 1200px;
  padding: 30px;
  margin: 25px auto;
  cursor: pointer;
}

.home-card {
  min-width: 300px;
  max-width: 550px;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 3px #e8e8e8;
  padding: 15px;
  margin: 0 15px 15px 15px;
  border-radius: 3px;
  border-left: 6px solid #223e7f;
  position: relative;
}

.home-card:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.home-card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.error-page-container {
  width: 100vw;
  height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.error-page-content {
  margin: auto auto;
}

.error-label {
  text-align: center;
  color: #223e7f;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 25px;
}

.error-text {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

.error-icon {
  display: flex;
  align-items: center;
  margin: 35px auto;
  color: #223e7f;
}

.pmt-file-dl-btn {
  min-width: 120px;
}

#toast-container > .toast-error {
  background-color: #bd362f;
}
#toast-container > .toast-success {
  background-color: #51a351;
}

.pa-sidebar {
  height: calc(100vh - 65px) !important;
  min-width: 230px;
  max-width: 230px;
  top: 65px;
  background-color: #223e7f;
}

.pa-contents {
  height: calc(100vh - 65px) !important;
  width: 100%;
  padding: 25px 25px;
}

.pa-contents .RT-TabSet--scrollable {
  height: calc(100vh - 160px) !important;
}

.pa-contents .RT-Section .RT-Static-Field {
  border-bottom: none;
}

.sidebar-policy-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 11px;
  height: 40px;
  letter-spacing: 0.2px;
  color: #adb5bd;
  font-size: 14px;
  position: relative;
  border-radius: 2px;
}

.sidebar-policy-link:hover {
  color: #fff;
}

.pa-contents .RT-Stackable-Container__body {
  overflow: hidden !important;
}

.search-card-container {
  max-width: 1200px;
  padding: 30px;
  margin: 50px auto;
}

.search-card {
  width: 100%;
  min-height: 500px;
  background-color: #fff;
  box-shadow: 0 0 3px #e8e8e8;
  padding: 15px;
  margin: 0 15px 15px 15px;
  border-radius: 3px;
  position: relative;
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.pad-spinner {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.pad-container {
  width: 500px;
  position: absolute;
  top: 150px;
  margin: auto;
  left: 0;
  right: 0;
}

.pad-form {
  width: 500px;
  position: absolute;
  top: 100px;
  margin: auto;
  left: 0;
  right: 0;
}

.react-modal .modal-dialog {
  height: 100vh !important;
  display: flex;
  max-width: 800px;
}

.react-modal .modal-content {
  margin: auto !important;
  height: fit-content !important;
}

.modal-backdrop {
  background-color: rgba(0, 25, 50, 0.12) !important;
}

.modal {
  height: unset !important;
}

.react-modal-title .modal-title {
  font-size: 17px !important;
}

.modal-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  padding: 25px !important;
}

.modal-content {
  border: 0px !important;
}

.modal-body {
  background-color: #f4f6f8;
  padding: 25px;
  flex-shrink: 1;
  overflow-y: hidden;
}

.modal-footer {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 25px !important;
}

.react-modal-confirm .modal-dialog {
  max-width: 500px;
}

.link-blue {
  color: rgb(73, 135, 239);
}

.btn-danger {
  color: #fff !important;
  background-color: #e32717 !important;
}

#root > div > div:nth-child(3) > div > div:nth-child(2) > div {
  height: calc(100vh - 65px);
}

#root
  > div
  > div:nth-child(3)
  > div
  > div:nth-child(2)
  > div
  > div
  > div.RT-Stackable-Container__main
  > div.RT-Stackable-Container__body {
  padding-bottom: 75px;
}

div.react-date-picker.react-date-picker--open.react-date-picker--enabled
  > span
  > div {
  position: fixed !important;
  margin-top: 29px;
  inset: unset !important;
}

.home-card-container,
.nhc-nav-bar,
.App {
  animation: cssAnimation 0s 1s forwards;
  visibility: hidden;
}
@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}

.landing-spinner-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  animation: cssAnimation 0s 2s forwards;
  visibility: visible;
}
.landing-spinner {
  position: absolute !important;
  right: 0;
  left: 0;
  top: 50%;
  margin: auto;
}

.adj-date-col {
  width: 100px;
}
.adj-credit-col {
  width: 75px;
  text-align: right;
}
.adj-new-col {
  width: 75px;
  text-align: right;
}
.adj-diff-col {
  width: 75px;
  text-align: right;
}
.adj-reason-col {
  width: 250px;
}
.adj-createdby-col {
  width: 100px;
  text-align: right;
}

.ktm-funding-name {
  font-size: 24px;
  font-weight: 500;
}
.ktm-funding-balance-label {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 10px;
  display: flex;
}
.ktm-funding-balance {
  font-size: 20px;
  color: green;
  font-weight: 500;
  padding-left: 10px;
}
.ktm-funding-balance-label-right {
  margin-left: auto;
}
.ktm-funding-balance-container {
  display: flex;
  justify-content: space-between;
}
.no-focus-shadow {
  box-shadow: none !important;
}

.dashboard-summary-card {
  padding: 10px;
  border-radius: 5px;
}
.dashboard-summary-header {
  font-size: 24px;
}
.dashboard-summary-value {
  font-size: 30px;
}

/* .dashboard-summary-card:hover {
box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
} */

.db-daily {
  background-color: #223e7f;
  color: #fff;
  margin-bottom: 15px;
  padding: 25px;
  border-radius: 15px;
}
.db-claims {
  background-color: #fff;
  color: #223e7f;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 25px;
  border-radius: 15px;
  border: 2px solid #223e7f;
}

.dashboard-spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100px;
}

.recharts-surface,
.recharts-wrapper {
  width: 100% !important;
}

.LegendList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none; /* Remove default bullets */
}

.LegendList li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.BulletLabel {
  display: flex;
  align-items: center;
}

.CirecleBullet {
  border-radius: 50%;
}

.BulletLabelText {
  margin-left: 10px;
}

.CenteredLabelValue {
  font-size: 36px;
  font-weight: 600;
  fill: #0088fe;
}

.chart-container {
  max-width: 100%;
}

@media screen and (max-width: 600px) {
  .chart-container {
    max-width: 80%;
  }
}

.recharts-legend-wrapper {
  width: unset !important;
  position: relative !important;
}

.funding-notification-card .RT-Section {
  min-height: 140px !important;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: #223e7f;
  color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.text-right {
  text-align: right;
}

.enrollment-date-picker .react-date-picker__wrapper {
  background-color: #ffffff;
  color: #0a53be;
}

.enrollment-date-picker label {
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.hsaCard {
  color: white;
  font-size: 18px;
  padding: 10px;
  background-color: #223e7f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lsaCard {
  color: white;
  font-size: 18px;
  padding: 10px;
  background-color: #059454;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}