.previous-year-section {
    background-color: #cedef6;
}

.RT-Section--background-previous_year_credit_calculation {
    margin-top: 15px;
}

.RT-Section--background-previous_year_flex_settings,
.RT-Section--background-previous_year_flex_limits,
.RT-Section--background-previous_year_hsa_settings,
.RT-Section--background-previous_year_hsa_limits,
.RT-Section--background-previous_year_lsa_settings,
.RT-Section--background-previous_year_lsa_limits,
.RT-Section--background-previous-year-section {
    background-color: #cedef6;
}

.data-grid.runoff-section .RT-DataTable__header {
    background-color: #cedef6;
}

.data-grid.runoff-section .RT-DataTable__row {
    background-color: #cedef6;
}